import React from "react";
import {Routes, Route} from "react-router";
import HomePage from "./pages/HomePage";
import PricesPage from "./pages/PricesPage";
import InfoPage from "./pages/InfoPage";
import ContactPage from "./pages/ContactPage";

const Routing: React.FC = ({ }) => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/precios" element={<PricesPage />} />
    <Route path="/info" element={<InfoPage />} />
    <Route path="/contacto" element={<ContactPage />} />
  </Routes>
)

export default Routing;
