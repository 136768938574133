import React from 'react';
import './App.css';

interface AppProps extends React.PropsWithChildren {
  children?: React.ReactNode
}

const App: React.FC<AppProps> = ({ children }) => (
    <div className="App">
      <header className="App-header">
        {children}
      </header>
    </div>
  );

export default App;
